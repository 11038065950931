import React from 'react'
import OrderCardComponent from './order-card.component'

const BookingHistorySection = () => {
  return (
    <div className="container mt-4  py-4">
        <OrderCardComponent/>
      </div>
  )
}

export default BookingHistorySection
